<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">ID</th>
                <th class="text-left">Názov</th>
                <th class="text-left">Publikové</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in items"
                :key="item.name"
                @click="selectedItem = item"
              >
                <td>{{ item.id }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.published }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-btn
          color="success"
          @click="dialog = true"
        >Nová stránka</v-btn>
      </v-col>
    </v-row>

    <div
      v-if="selectedItem != null"
      class="my-10"
    >
      <v-row>
        <v-col>
          <v-text-field
            label="Názov"
            v-model="selectedItem.name"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <editor v-model="selectedItem.content" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-checkbox
            label="Publikované"
            v-model="selectedItem.published"
          ></v-checkbox>
          <v-btn
            color="primary"
            @click="updateItem(selectedItem)"
          >Uložiť</v-btn>
        </v-col>
        <v-col>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            @click="deleteItem(selectedItem)"
          >Zmazať</v-btn>
        </v-col>
      </v-row>
    </div>

    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Settings</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              dark
              text
              @click="createItem(newItem)"
            > Vytvoriť </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-list
          three-line
          subheader
        >
          <v-subheader>Názov</v-subheader>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Názov stránky</v-list-item-title>
              <v-list-item-subtitle>
                <v-text-field v-model="newItem.name"></v-text-field>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { APIService } from "@/http/APIService.js";
import Editor from "@/components/Editor.vue"

const apiService = new APIService();

export default {
  name: "admin-pages",

  components: { Editor },

  data: () => ({
    loading: false,
    dialog: false,

    items: [],
    selectedItem: null,

    newItem: {
      name: "",
      content: "",
      published: false,
    },
  }),

  mounted: function () {
    this.getItems();
  },

  methods: {
    getItems() {
      this.loading = true;
      apiService.getAdminFlatPages().then((response) => {
        this.items = response.results;
        this.loading = false;
      });
    },

    createItem(item) {
      this.loading = true;
      this.dialog = false;
      apiService.createAdminPage(item).then((response) => {
        this.item = response;
        this.loading = false;

        this.getItems();
      });
    },

    updateItem(item) {
      this.loading = true;
      apiService.updateAdminFlatPage(item).then((response) => {
        this.item = response.results;
        this.loading = false;
      });
    },

    deleteItem(item) {
      this.loading = true;
      apiService.deleteAdminFlatPage(item).then((response) => {
        this.item = response.results;
        this.loading = false;
        this.getItems();
      });
    },
  },
};
</script>

<style scoped>
.dragArea {
  min-height: 50px;
  outline: 1px dashed;
}
</style>