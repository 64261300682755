<template>
  <div>
    <v-card>
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <v-card class="mx-auto" tile>
              <nested-draggable v-model="items" :items="items" :depth="1" />
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="auto"
            ><v-btn @click="save()" color="primary">Uložiť</v-btn></v-col
          >
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { APIService } from "@/http/APIService.js";
import nestedDraggable from "@/components/Nested";

const apiService = new APIService();

export default {
  name: "admin-menu",

  components: {
    nestedDraggable,
  },

  data: () => ({
    loading: false,
    drag: false,

    items: [],
    item: null,
  }),

  mounted: function () {
    this.getItems();
  },

  methods: {
    save() {
      let flatten = (children, getChildren, level, parent) =>
        Array.prototype.concat.apply(
          children.map((x) => ({
            ...x,
            level: level || 1,
            parentId: function () {
              if (parent) {
                return parent.id;
              } else {
                return null;
              }
            },
          })),
          children.map((x) =>
            flatten(getChildren(x) || [], getChildren, (level || 1) + 1, x)
          )
        );

      let extractChildren = (x) => x.children;

      let flat = flatten(this.items, extractChildren).map(
        (x) => delete x.children && x
      );

      console.log(flat);

      flat.forEach((element, index) => {
        element.parent = element.parentId();
        element.order = index;

        this.updateItem(element);
      });
    },

    getItems() {
      this.loading = true;
      apiService.getAdminPages().then((response) => {
        this.items = response.results;
        this.loading = false;
      });
    },

    updateItem(item) {
      this.loading = true;
      apiService.updateAdminFlatPage(item).then((response) => {
        this.item = response;
        this.loading = false;
      });
    },
  },
};
</script>
