<template>
  <draggable
    class="dragArea"
    tag="ul"
    :list="items"
    :group="{ name: 'g1' }"
    @input="emitter"
    :value="value"
  >
    <li v-for="el in items" :key="el.name">
      <p>{{ el.name }}</p>
      <nested-draggable :items="el.children" :depth="depth + 1" v-if="depth < 2"/>
    </li>
  </draggable>
</template>
<script>
import draggable from "vuedraggable";

export default {
  props: {
    value: {
      required: false,
      type: Array,
      default: null,
    },
    items: {
      required: false,
      type: Array,
      default: null,
    },
    depth: {
      required: false,
      type: Number,
      default: 1,
    }
  },
  components: {
    draggable,
  },
  name: "nested-draggable",

  methods: {
    emitter(value) {
      this.$emit("input", value);
    },
  },
};
</script>
<style scoped>
.dragArea {
  min-height: 50px;
  outline: 1px dashed;
}
</style>